body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

#root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

/* D3 start*/
/* Changing font size for SVG impacts ALL icons. Proceed with caution */
/* svg {
    font: 10px sans-serif;
} */

.y.axis path {
    display: none;
}

.y.axis line {
    stroke: #fff;
    stroke-opacity: 0.2;
    shape-rendering: crispEdges;
}

.y.axis .zero line {
    stroke: #000;
    stroke-opacity: 1;
}

.title {
    font: 300 78px Helvetica Neue;
    fill: #666;
}

.birthyear,
.age {
    text-anchor: middle;
}

.birthyear {
    fill: #fff;
}

/* rect {
    fill-opacity: 0.6;
    fill: #e377c2;
}

rect:first-child {
    fill: #1f77b4;
} */

/* rect {
    fill: #1f77b4;
} */
.primarybar {
    fill-opacity: 1;
}

.secondarybar {
    fill-opacity: 0.7;
}

.tertiarybar {
    fill-opacity: 0.5;
}

.femalebar {
    fill-opacity: 0.6;
    fill: #e377c2;
}

.malebar:first-child {
    fill: #1f77b4;
}

text.shadow {
    font-size: 0.6em;
    stroke: white;
    stroke-width: 1px;
    fill: black;
}

.focus circle {
    fill: none;
    stroke: steelblue;
}

/* D3 end */

/* Swiper CSS overrides*/
.swiper-slide-liveable {
    flex-shrink: 0;
    /* width: 100%; */
    height: 100%;
    position: relative;
    transition-property: transform;
}
