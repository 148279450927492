.mortgage-repayment-amount {
    font-size: 2rem;
    font-weight: 650;
    color: #315990;
}

.uno-terms {
    font-size: 0.7rem;
}

.uno-logo {
    max-width: 250px;
    width: 100%;
}

.validation-error {
    color: red;
}
