.naplan-table tr:nth-child(12n + 7),
.naplan-table tr:nth-child(12n + 8),
.naplan-table tr:nth-child(12n + 9),
.naplan-table tr:nth-child(12n + 10),
.naplan-table tr:nth-child(12n + 11),
.naplan-table tr:nth-child(12n + 12) {
    background: #eeeeee;
}

.naplan-score {
    font-size: 1.1rem;
}
