.App {
    margin-top: 15px;
}

.App .navbar-brand {
    font-weight: bold;
}

.footer {
    font-size: small;
    /* margin-top: auto; */
}

.secondary-footer {
    line-height: 2em;
    margin-top: auto;
}

.footer a {
    color: #eee;
    text-decoration: none;
    text-transform: none;
}

.footer a:hover {
    color: #ffa800;
    background-color: transparent;
    text-decoration: none;
}

.footer .widget-title {
    font-size: 1.2em;
    border-bottom: solid 1px #eeeeee;
    padding-bottom: 0.7em;
    text-transform: uppercase;
    color: #ffffff !important;
}

hr {
    border-top: 1px solid #315990;
    margin-top: 3em;
    margin-bottom: 2em;
}

body {
    color: #333333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #315990;
}

h1 {
    font-size: 2.1rem;
}

h2 {
    font-size: 1.9rem;
}

h3 {
    font-size: 1.5rem;
}

h4 {
    font-size: 1.35rem;
}
h5 {
    font-size: 1.2rem;
}

.carousel-wrapper {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.carousel-card {
    flex: 0 0 auto;
}

.carousel-wrapper::-webkit-scrollbar {
    display: none;
}

@media screen and (min-width: 767.98px) {
    .navbar-nav.carousel-wrapper {
        flex-wrap: wrap;
    }
}

#nav-header {
    color: #333333;
    z-index: 1;
}

#nav-header .navbar-nav {
    padding-left: 1vw;
    padding-right: 1vw;
}

@media screen and (max-width: 767.98px) {
    #nav-header .navbar-nav {
        padding-left: 0;
        padding-right: 0;
    }

    #nav-header .navbar-nav .carousel-card {
        flex: 0 0 auto;
    }
}

@media screen and (min-width: 767.99px) {
    #nav-header .navbar-nav .carousel-card {
        flex: auto;
        padding-bottom: 0.2em;
    }
}

#nav-header .carousel-card {
    display: inline-flex;
    padding-left: 0.3em;
    padding-right: 0.5em;
    padding-top: 0.2em;
    padding-bottom: 0.4em;
}

@media screen and (max-width: 767.98px) {
    #nav-header .carousel-card {
        padding-left: 0;
    }
}
#nav-header a {
    color: #666666;
    text-decoration: none;
    text-transform: none;
}

#nav-header a:hover {
    color: #315990;
    background-color: transparent;
    text-decoration: none;
}

#nav-header .active a {
    color: #315990;
    font-weight: bold;
}

.disabled {
    color: #999999 !important;
    font-weight: normal !important;
}

#nav-header ul {
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#nav-header ul li {
    display: inline-flex;
}

.pointer {
    cursor: pointer;
}

#places-autosuggest {
    z-index: 1000;
}

.FacebookButton {
    background-color: #3b5998;
    border-color: #3b5998;
}
