.header-bg-splash {
    width: 100%;
    min-height: 550px;
    background-size: cover;
    background: linear-gradient(
            to bottom,
            rgba(0, 30, 60, 0.75),
            rgba(0, 0, 0, 0.7)
        ),
        url("https://liveable.co/images/home_splash_bg.jpeg") center;
    border-bottom: 10px solid #cccccc;
}

@media screen and (max-width: 575.98px) {
    .header-bg-splash {
        min-height: 550px;
    }
    .header-bg-area-splash {
        min-height: 550px;
    }
    .header-bg-street-splash {
        min-height: 550px;
    }
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
    .header-bg-splash {
        min-height: 75vh;
    }
    .header-bg-area-splash {
        min-height: 75vh;
    }
    .header-bg-street-splash {
        min-height: 75vh;
    }
}

.header-bg-area-splash {
    width: 100%;
    min-height: 550px;
    background-size: cover;
    background: linear-gradient(
            to bottom,
            rgba(0, 30, 60, 0.65),
            rgba(0, 0, 0, 0.55)
        ),
        url("https://liveable.co/images/area_splash_bg.jpeg") center;
    border-bottom: 10px solid #cccccc;
}

.header-bg-street-splash {
    width: 100%;
    min-height: 550px;
    background-size: cover;
    background: linear-gradient(
            to bottom,
            rgba(0, 30, 60, 0.65),
            rgba(0, 0, 0, 0.55)
        ),
        url("https://liveable.co/images/street_splash_bg.jpeg") center;
    border-bottom: 10px solid #cccccc;
}

.header-text {
    color: #ffffff;
}

.header-subtitle {
    font-size: 1.5rem;
}

.icon-large {
    font-size: 2.5rem;
}

.section-title {
    font-size: 1.6rem;
}

.background-grey {
    background-color: #efefef;
}

.liveable-blue {
    color: #315990;
}

a {
    color: #315990;
    text-decoration: none;
    text-transform: none;
}

a:hover {
    color: #ffa800;
    background-color: transparent;
    text-decoration: none;
}

/* Work-around as AddressTextBox includes a style that sets opacity:0 in Home but not anywhere else */
.dropdown-menu {
    opacity: 1 !important;
    pointer-events: all !important;
}
